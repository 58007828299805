import Vue from 'vue'
import App from './App.vue'
// 引入路由
import router from './router'
// 引入vant库
import '@/config/vant.config'

Vue.config.productionTip = false

//路由发生变化修改页面title
router.beforeEach((to,from,next) => {
  if(to.meta.title){
    document.title = to.meta.title
  }
  next()
})

import*as API from '@/api'

new Vue({
  render: h => h(App),
  // 配置去全局的api请求
  beforeCreate(){
    Vue.prototype.$API = API;
  },
  router
}).$mount('#app')

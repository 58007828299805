//配置路由的地方
import Vue from "vue";
import VueRouter from "vue-router";

//引入抽出去的路由配置信息
import routes from './routes';

//使用插件
Vue.use(VueRouter);
// 编程式路由跳转到当前路由（参数不变），多次执行会抛出NavigationDuplicated的错误警告,以下是解决方法
//需要重写VueRouter.prototype原型对象身上的push|reeplacee方法
//先把VueRouter.prototype身上的push|replacee方法进行保存一份
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
// 重写push|replace
// 第一个参数：告诉原来push方法，你往哪里跳转（传递哪些参数）
// 第二个参数：成功的回调
// 第三个参数：失败的回调
//  call || apply区别
//  相同点，都可以调用函数一次，都可以篡改函数的上下文一次
//  不同点：call与apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        //call||apply区别/*  */
        //相同点，都可以调用函数一次，都可以篡改函数的上下文一次
        //不痛点：call与apply传递参数：call传递参数用逗号隔离，apply方法执行，传递数组
        originPush.call(this.location, resolve, reject);
    } else {
        originPush.call(this, location, () => { }, () => { });
    }
}
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        //call||apply区别
        //相同点，都可以调用函数一次，都可以篡改函数的上下文一次
        //不痛点：call与apply传递参数：call传递参数用逗号隔离，apply方法执行，传递数组
        originReplace.call(this.location, resolve, reject);
    } else {
        originReplace.call(this, location, () => { }, () => { });
    }
}

//配置路由，对外暴露VueRouter类的实例
let router = new VueRouter({
    //配置路由
    //第一：路径的前面需要有/(二级路由不要/)
    //路径中的单词都是小写的
    // 因为页面信息可能比较多，容易乱，所以配置的路由信息都抽离出在routes文件中，这里只需要引入注册就可以了
    routes,
    scrollBehavior(to, from, savedPosition) {
        //return 期望滚到哪个的位置
        return { y: 0 }
    }
})

// 全局守卫：前置守卫(在路由跳转之前判断)
router.beforeEach((to, from, next) => {
    //to：可以获取到你要跳转到哪个路由的信息
    //from:可以获取到你从哪个路由而来的信息
    //next:放行函数  next()放行
    // 用户登录才会有nickName，没有登录的话就不会有nickName
    let token = window.sessionStorage.getItem('token');
    console.log(token);
    if (to.path == '/withdrawalPage') {
        if (!token) {
            window.location.href = 'https://wx.gdjdtec.com/auth.html?base_uri=https%3A%2F%2Fwx.gdjdtec.com%2Fauth.html&app_id=wx5dbba2486456d0b7'
        } else {
            next()
        }
    } else {
        next()
    }
})


//对外暴露
export default router
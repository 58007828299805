import Vue from "vue";
import 'vant/lib/index.css'
import { Tabbar, TabbarItem, Icon, Button, Field, Popup, ActionSheet, Form, Loading } from 'vant'

Vue.use(Tabbar)
Vue.use(TabbarItem)
Vue.use(Icon)
Vue.use(Button)
Vue.use(Field)
Vue.use(Popup)
Vue.use(ActionSheet)
Vue.use(Form)
Vue.use(Loading)
//同意管理项目接口的模块
//当前这个模块：API进行统一管理 
import requests from "./request";

// 获取用户订单推广列表接口
export const reqGetUserOrder = (token) => requests({ url: '/policy/list', method: "post", params: { token: token } })


// 我要推广接口
export const reqGetState = (token, guaranteeSlipId) => requests({ url: '/policy/receive', method: "post", params: { token: token, id: guaranteeSlipId } })

// 完成提交保单号
export const reqGetSubmitPolicyNumber = (token, guaranteeSlipId, warrantyNumber) => requests({ url: '/policy/finish', method: "post", params: { token: token, id: guaranteeSlipId, policyNo: warrantyNumber } })

// 获取交易明细
export const reqTransactionDetail = (commitToken) => requests({ url: '/policy/commissionList', method: 'post', params: { token: commitToken } })

// 新增的微信提现接口
export const reqPostWithdrawal = (token, accountName, amount) => requests({ url: '/jzWx/withdrawal', method: 'post', params: { token, accountName, amount } })
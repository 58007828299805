<template>
  <div>
    <div class="footer-center" v-show="hidshow">
      <van-tabbar v-model="active" route>
        <van-tabbar-item icon="home-o" to="/home">推广</van-tabbar-item>
        <van-tabbar-item icon="cash-back-record" to="/commission">佣金</van-tabbar-item>
        <van-tabbar-item icon="manager-o" to="/personalcenter">个人中心</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      docmHeight: window.innerHeight, //默认屏幕高度
      showHeight: window.innerHeight, //实时屏幕高度
      hidshow: true //显示或者隐藏footer
    };
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.showHeight = window.innerHeight
      console.log('this.showHeight:' + this.showHeight);
      console.log('this.docmHeight:' + this.docmHeight);
      if (this.docmHeight - this.showHeight > 100) {
        //Android系统: 软键盘弹出 隐藏footer
        this.hidshow = false
      } else {
        //Android系统: 软键盘收起 显示footer
        this.hidshow = true
      }
    });
  },
};
</script>

<style scoped lang="scss">
 ::v-deep .van-icon{
    font-weight: 600;
    font-size: 3rem;
 }
 ::v-deep .van-hairline--top-bottom{
    height: 6.5rem;
 }
</style>
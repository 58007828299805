
//这路由里面的配置信息抽出来，单独放在一个js当中，然后导出去
export default [
    {
        path: '/',
        redirect: '/home'
    },
    {
        path: '/home',
        name: 'home',
        meta: { title: '推广', show: true },
        component: () => import('@/view/home')
    },
    {
        path: '/commission',
        name: 'commission',
        meta: { title: '佣金', show: true },
        component: () => import('@/view/commission')
    },
    {
        path: '/personalcenter',
        name: 'personalcenter',
        meta: { title: '个人中心', show: true },
        component: () => import('@/view/personalcenter')
    },
    {
        path: '/withdrawDeposit',
        name: 'withdrawDeposit',
        meta: { title: '微信提现', show: true },
        component: () => import('@/view/withdrawDeposit')
    },
    {
        path: '/withdrawalPage',
        name: 'withdrawalPage',
        meta: { title: '提现', show: false },
        component: () => import('@/view/withdrawalPage')
    },
    {
        path: '/success',
        name: 'success',
        meta: { title: '提交', show: false },
        component: () => import('@/view/Success')
    },
    {
        path: '/auth',
        name: 'auth',
        meta: { title: '提交', show: false },
        component: () => import('@/view/auth')
    }
]
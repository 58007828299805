<template>
  <div id="app">
    <!-- 底部tabbar栏 -->
    <Footer v-show="$route.meta.show"></Footer>
    <router-view></router-view>
    <div class="bottom-safe-area" v-show="$route.meta.show"></div>
  </div>
</template>

<script>
import Footer from "@/components/Footer/index.vue"
export default {
  name: 'App',
  components: {
    Footer
  },
  created(){
    this.onchangeWindowSize();
    window.onresize = this.onchangeWindowSize;
  },
  methods:{
    onchangeWindowSize(){
      const rootFontSize = window.innerWidth * (16 / 812);
      document.getElementsByTagName(
        "html"
      )[0].style.fontSize = `${rootFontSize}px`;
    },
  },
}
</script>

<style scoped lang="scss">
.bottom-safe-area{
  height: 6rem;
}
</style>
